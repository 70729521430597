import axios from "./axios.js";

const LOGIN_URL = '/api/login_check';
export const login = async (username, password) => {

    const response = await axios.post(LOGIN_URL, JSON.stringify({ username: username, password: password }));

    if (!response.status === 200) {
        throw new Error('Login failed');
    }
    const data = response.data;

    return typeof data === 'string' ? JSON.parse(data) : data;

};
