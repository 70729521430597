import { Link } from "react-router-dom";

const Dashboard = () => {

    return(
        <>
        
        </>
    );
}

export default Dashboard;