// import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoute = ({ allowedRoles }) => {
    const { user, roles, token } = useSelector((state) => state.auth);

    if (!token) {
        return <Navigate to="/login" />;
    }

    const hasPermission = roles.some((role) => allowedRoles.includes(role));

    if (!hasPermission) {
        return <Navigate to="/dashboard" />;
    }

    return <Outlet />;
};
