const UsersInternal = () => {

    return(
        <>
        users internal
        </>
    );

}

export default UsersInternal;