import { createSlice } from '@reduxjs/toolkit';
import {login as loginApi } from '../api/authApi.js';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: sessionStorage.getItem('user') || null,
        profile: sessionStorage.getItem('profile') || null,
        token: sessionStorage.getItem('token') || null,
        roles: JSON.parse(sessionStorage.getItem('roles') || '[]')
    },
    reducers: {
        setCredentials: (state, action) => {
            state.user = action.payload.user;
            state.profile = action.payload.profile;
            state.token = action.payload.token;
            state.roles = action.payload.roles;
            sessionStorage.setItem('token', action.payload.token);
            sessionStorage.setItem('roles', JSON.stringify(action.payload.roles));
            sessionStorage.setItem('profile', action.payload.profile);
            sessionStorage.setItem('user', action.payload.user);
        },
        logout: (state) => {
            state.user = null;
            state.profile = null;
            state.token = null;
            state.roles = [];
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('roles');
            sessionStorage.removeItem('profile');
            sessionStorage.removeItem('user');
        },
    },
});

export const { setCredentials, logout } = authSlice.actions;

export const login = (username, password) => async (dispatch) => {
    const data = await loginApi(username, password);
    dispatch(setCredentials({ user: data.user,  profile: data.profile, token: data.token, roles: data.roles }));
};

export default authSlice.reducer;
