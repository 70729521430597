import {useTranslation} from "react-i18next";


const TemplateFooter = () => {
    const { t } = useTranslation();
    return(
        <>

        <footer id="footer" className="footer">
            <div className="copyright">
            {new Date().getFullYear()}&copy; Copyright <strong><span>{t('company name')}</span></strong>. All Rights Reserved
            </div>
            <div className="credits">
            {/* <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ --> */}
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div>
        </footer>
        </>
    )
}

export default TemplateFooter;