import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function MobileNav() {
    const [isNavActive, setNavActive] = useState(false);

    const toggleMobileNav = () => {
        setNavActive(!isNavActive);
    };

    useEffect(() => {
        const body = document.querySelector('body');
        const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');

        if (isNavActive) {
            body.classList.add('mobile-nav-active');
            mobileNavToggleBtn.classList.add('bi-x');
            mobileNavToggleBtn.classList.remove('bi-list');
        } else {
            body.classList.remove('mobile-nav-active');
            mobileNavToggleBtn.classList.remove('bi-x');
            mobileNavToggleBtn.classList.add('bi-list');
        }
    }, [isNavActive]);

    const handleNavLinkClick = () => {
        if (isNavActive) {
            toggleMobileNav();
        }
    };

    return (
        <nav id="navmenu" className={`navmenu ${isNavActive ? 'mobile-nav-active' : ''}`}>
            <ul>
                <li>
                    <Link to="/#hero" className="active text-capitalize-first" onClick={handleNavLinkClick}>
                        Inicio
                    </Link>
                </li>
                <li>
                    <Link to="/#about" className="text-capitalize-first" onClick={handleNavLinkClick}>
                        Acerca de nosotros
                    </Link>
                </li>
                <li>
                    <Link to="/#features" className="text-capitalize-first" onClick={handleNavLinkClick}>
                        Como funciona
                    </Link>
                </li>
                <li>
                    <Link to="/#services" className="text-capitalize-first" onClick={handleNavLinkClick}>
                        Servicios
                    </Link>
                </li>
                <li className="text-capitalize-first">
                    <Link to="/#pricing" className="text-capitalize-first" onClick={handleNavLinkClick}>
                        Precios
                    </Link>
                </li>
                <li>
                    <Link to="/#contact" className="text-capitalize-first" onClick={handleNavLinkClick}>
                        Contacto
                    </Link>
                </li>
            </ul>

            <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={toggleMobileNav}></i>
        </nav>
    );
}

export default MobileNav;

