import { useEffect, useRef, useState } from 'react';
import api from "../../../api/api.js";
import $ from 'jquery';
import 'datatables.net'
const Companies = () => {

    const tableRef = useRef(null);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        const table = $(tableRef.current).DataTable({
            processing: true,
            serverSide: true,
            ajax: async (dataTablesParams, callback) => {
                try {
                    const response = await api.get('/companies', {
                        params: {
                            page: dataTablesParams.start / dataTablesParams.length + 1,
                            itemsPerPage: dataTablesParams.length,
                        },
                    });

                    callback({
                        data: response.data['hydra:member'],
                        recordsTotal: response.data['hydra:totalItems'],
                        recordsFiltered: response.data['hydra:totalItems'],
                    });
                } catch (error) {
                    console.error('Error al cargar datos:', error);
                    callback({ data: [] });
                }
            },
            columns: [
                { title: 'Nombre', data: 'name' },
                { title: 'creado hace', data: 'createdAtAgo' },
            ],
        });

        return () => {
            table.destroy();
        };
    }, []);

    return(
        <>
        <div className="col">
            <div className="card">
                <div className="card-body p-1">
                    <div className="card-title p-1">
                        <h5 className="card-title p-1">Lista de compañias</h5>
                    </div>
                    <div className={"card-body"}>
                        <table ref={tableRef} className="display" style={{width: '100%'}}></table>
                    </div>
                </div>
            </div>
        </div>
        </>
    );

}

export default Companies;